import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import LanguageSwitch from "../components/languageSwitch"
import ThemeSwitch from "../components/themeSwitch"
import SoundButton from "../components/soundToggle"
import BlobGradient from "../assets/blobWithGradient1.inline.svg"
import Dotpattern from "../assets/dot_pattern.inline.svg"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import Page404 from "../assets/pnf.inline.svg"
import * as styles from "../styles/style404.module.css"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import {
  ListTLink,
  transitionFadeOut,
  transitionFadeIn,
} from "../components/layout"
import { Helmet } from "react-helmet"
import useSound from "use-sound"
import ButtonClick from "../sounds/button-click.mp3"
import { GlobalContext } from "../components/soundToggle"
import { gsap } from "gsap/dist/gsap"
import { usePrefersReducedMotion } from "../components/prefersReducedMotion"
import { floating404 } from "../components/GSAPanimations"

const NotFound = ({ location }) => {
  const prefersReducedMotion = usePrefersReducedMotion()
  const { language } = useI18next()
  const { t } = useTranslation()
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })

  let linkTarget
  if (language === "da") {
    linkTarget = "/"
  } else {
    linkTarget = `/${language}/`
  }

  const openContact = () => {
    play()
    const contactModal =
      typeof window !== `undefined`
        ? document.querySelector(".contactModalOpen")
        : undefined
    contactModal.click()
  }

  useEffect(() => {
    const blob1 = document.querySelector("[class*='blob1']")
    const pageNum = document.querySelector("[class*='pageNumber']")
    const dot1 = document.querySelector("[class*='dotPattern1']")
    gsap.killTweensOf(blob1, pageNum, dot1)
    if (prefersReducedMotion === true) {
      return
    }
    floating404(blob1, pageNum, dot1)
  }, [prefersReducedMotion])

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <Seo
        title={t("404.SEO_TITLE")}
        description={t("404.SEO_DESCRIPTION")}
        pathName={location.pathname}
      />
      <main className="bodyWrapper">
        <LanguageSwitch />
        <SoundButton />
        <ThemeSwitch />
        <section style={{ overflow: "visible" }} className="card wide">
          <Page404 className={styles.pageNumber} />
          <h1 className={styles.Display1}>{t("404.PAGE_NOT_FOUND")}</h1>
          <BlobGradient className={styles.blob1} />
          <Dotpattern className={styles.dotPattern1} />
          <StaticImage
            src="../images/404.png"
            alt={t("404.LAPTOP_IMAGE_ALT")}
            placeholder="blurred"
            layout="constrained"
            width={500}
            loading="eager"
            style={{
              display: "block",
              maxWidth: "500px",
              zIndex: 10,
              margin: "0 auto",
            }}
          />
          <p style={{ textAlign: "center", margin: "0 0 8px 0" }}>
            {t("404.THE_LINK_YOU_CLICKED")}
          </p>
          <span
            style={{
              textAlign: "center",
              display: "inline-block",
              width: "100%",
            }}
          >
            <p className={styles.pre}>{t("404.VISIT_MY")}</p>
            <ListTLink
              onClick={play}
              to={linkTarget}
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              {t("404.INDEXPAGE")}
            </ListTLink>
            <p className={styles.pre}>{t("404.OR")}</p>
            <button
              class="noHrefLink"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={openContact}
              tabindex="0"
            >
              {t("404.CONTACT_ME")}
            </button>
            <p className={styles.pre}>{t("404.ABOUT_THE_PROBLEM")}</p>
          </span>
        </section>
      </main>
    </>
  )
}

export default NotFound

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
